import { appVersion } from './env';

function getDevice() {
  if (isConsole()) {
    return 'console';
  }

  if (isTablet()) {
    return 'tablet';
  }

  if (isMobile()) {
    return 'mobile';
  }

  if (isTV()) {
    return 'tv';
  }

  return 'desktop';
}

function isConsole(userAgent) {
  return checkUserAgent(userAgent, /(playstation|xbox)/i);
}

function isMobile(userAgent) {
  return checkUserAgent(
    userAgent,
    /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/i,
  );
}

function isTablet(userAgent) {
  return checkUserAgent(
    userAgent,
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i,
  );
}

function isTV(userAgent) {
  try {
    const hasTVInAgent = checkUserAgent(
      userAgent,
      /tv|freebsd|sony|samsung|philips|panasonic|lg|roku/i,
    );

    if (hasTVInAgent) {
      return true;
    }

    const chromeVersion = parseInt(
      getUserAgent()
        .toLowerCase()
        .split('chrome/')[1]
        .slice(0, 2),
      10,
    );

    // When version is below 50 resolves to TV
    return chromeVersion <= 50;
  } catch (error) {
    return false;
  }
}

function isDesktop(userAgent) {
  return !isTV(userAgent) && !isTablet(userAgent) && !isMobile(userAgent);
}

function checkUserAgent(userAgent, regex) {
  const _userAgent = userAgent || getUserAgent();

  return regex.test(_userAgent);
}

function getUserAgent() {
  if (!global.navigator) {
    return '';
  }

  return navigator.userAgent;
}

function getLocation() {
  if (!global.window) {
    return '';
  }

  return window.location.href;
}

function getVersion() {
  return appVersion;
}

export default {
  getDevice,
  getUserAgent,
  getLocation,
  getVersion,
  isDesktop,
  isConsole,
  isTV,
  isMobile,
  isTablet,
};
