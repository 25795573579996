import React from 'react';
import { Helmet } from 'react-helmet';

import {
  baseAssetsUrl,
  hostnameUrl,
  isProductionEnv,
  zendeskID,
  hotjarID,
} from 'utils/env';

function HeaderTags({ metadata }) {
  return (
    <>
      {isProductionEnv()
        ? createProductionHeaderTags(metadata)
        : createDevelopmentHeaderTags(metadata)}
      {insertTagManagerHeader()}
      {insertTagManagerBody()}
      {insertGoogleAnalyticsForOptimize()}
      {insertOptimize()}
      {insertFacebookPixel(metadata)}
      {insertGoogleAnalytics(metadata)}
    </>
  );
}

function createProductionHeaderTags(metadata) {
  const { productName } = metadata;

  return (
    <>
      {optimize()}
      {insertProductionMetaTags(productName)}
      {insertZendeskChat()}
      {insertHotjar()}
      {insertTaboolaPixel(metadata)}
    </>
  );
}

function createDevelopmentHeaderTags(metadata) {
  const { productName } = metadata;
  const applicationName = 'Queima Pay';
  const title = `Checkout ${
    productName ? productName : ''
  } | ${applicationName}`;

  return (
    <Helmet>
      <html lang="pt-br" />
      <meta name="robots" content="noindex, nofollow" />
      <title>{title}</title>
    </Helmet>
  );
}

function insertProductionMetaTags(productName) {
  const largeDescription =
    'A Queima Diária é uma plataforma com programas de nutrição e exercícios para fazer em casa. Faça sua assinatura e tenha acesso ilimitado aos famosos programas: Mamãe Sarada, Desafio Yoga e muito mais!';
  const smallDescription =
    'Programas de exercícios para fazer em casa e emagrecer';
  const applicationName = 'Queima Pay';
  const title = `Checkout ${
    productName ? productName : ''
  } | ${applicationName}`;
  const websiteUrl = `https://${hostnameUrl}`;
  const imageUrl = `${baseAssetsUrl}/checkout/seo/queima-icon.png`;

  return (
    <Helmet>
      <html lang="pt-br" />
      <meta name="robots" content="noindex, nofollow" />

      <title>{title}</title>
      <meta name="description" content={smallDescription} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:description" content={largeDescription} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@queimadiaria" />
      <meta name="twitter:creator" content="@queimadiaria" />

      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:description" content={largeDescription} />
      <meta property="og:site_name" content={applicationName} />
      <meta property="og:url" content={websiteUrl} />
      <meta property="og:image:alt" content={`Logo do ${applicationName}`} />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:type" content="website" />

      <meta
        name="google-site-verification"
        content="heAWj65fWZHcDMfIlHCn9337PUFqzJ-pJ9iqU29iRwY"
      />
    </Helmet>
  );
}

const insertTagManagerHeader = () => {
  return (
    <Helmet>
      <script>
        {`function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-P4DPKK3');`}
      </script>
    </Helmet>
  );
};

const insertTagManagerBody = () => {
  return (
    <Helmet>
      <noscript>
        {`
        <iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-P4DPKK3'
          height='0'
          width='0'
          style='display:none; visibility:hidden'
        ></iframe>`}
      </noscript>
    </Helmet>
  );
};

const insertGoogleAnalyticsForOptimize = () => {
  return (
    <Helmet>
      <script>
        {`
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', 'UA-65884289-9', 'auto', { allowLinker: true });
        ga('send', 'pageview');
        `}
      </script>
    </Helmet>
  );
};

const insertOptimize = () => {
  return (
    <Helmet>
      <script src="https://www.googleoptimize.com/optimize.js?id=OPT-TSCTH6D"></script>
    </Helmet>
  );
};

function insertZendeskChat() {
  return (
    <Helmet>
      <script src={`//v2.zopim.com/?${zendeskID}`} />
    </Helmet>
  );
}

function insertHotjar() {
  return (
    <Helmet>
      <script>
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${hotjarID},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </script>
    </Helmet>
  );
}

function insertFacebookPixel(metadata) {
  const { pixels } = metadata;

  if (!pixels || !pixels.length) {
    return null;
  }

  // Defaults first pixel into <noscript> users
  const firstPixel = pixels[0];

  return (
    <Helmet>
      <script>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          ${pixels.map(id => `fbq('init', ${id});`).join('')}
          fbq('track', 'PageView');
          fbq('track', 'InitiateCheckout');
        `}
      </script>
      <noscript>
        {`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${firstPixel}&ev=PageView&noscript=1"/>
        `}
      </noscript>
    </Helmet>
  );
}

function insertTaboolaPixel(metadata) {
  const { taboolaPixel } = metadata;

  if (!taboolaPixel || !taboolaPixel.length) {
    return null;
  }

  return (
    <Helmet>
      <script>
        {`
          window._tfa = window._tfa || [];
          window._tfa.push({notify: 'event', name: 'page_view', id: ${taboolaPixel}});
          !function (t, f, a, x) {
                 if (!document.getElementById(x)) {
                    t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
                 }
          }(document.createElement('script'),
          document.getElementsByTagName('script')[0],
          '//cdn.taboola.com/libtrc/unip/${taboolaPixel}/tfa.js',
          'tb_tfa_script');
        `}
      </script>
      <noscript>
        {`
          <img src='https://trc.taboola.com/${taboolaPixel}/log/3/unip?en=page_view'
          width='0' height='0' style='display:none'/>
        `}
      </noscript>
    </Helmet>
  );
}

function insertGoogleAnalytics(metadata) {
  const { analytics } = metadata;

  if (!analytics || !analytics.length) {
    return null;
  }

  return (
    <Helmet>
      <script>
        {`
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
          ${analytics
            .map((uaId, index) => {
              const name = index === 0 ? "'auto'" : `'auto', '${index}'`;
              const prefix = index === 0 ? '' : `${index}.`;

              return `
                ga('create', '${uaId}', ${name}, { allowLinker: true });
                ga('${prefix}send', 'pageView');
                ga('${prefix}require', 'ecommerce');
              `;
            })
            .join('')
            .trim()}
        `}
      </script>
    </Helmet>
  );
}

function optimize() {
  return (
    <Helmet>
      <script src="https://www.googleoptimize.com/optimize.js?id=OPT-TSCTH6D"></script>
    </Helmet>
  );
}
export default HeaderTags;
