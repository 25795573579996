import React from 'react';
import createService from 'utils/createService';

function Routes() {
  const _screens = [
    {
      name: 'LandingPage',
      factory: () =>
        import(
          /* webpackChunkName: "LandingPage" */ 'screens/LandingPage/LandingPage'
        ),
      props: {
        path: '/',
        exact: true,
      },
    },
    {
      name: 'Checkout',
      factory: () =>
        import(/* webpackChunkName: "Checkout" */ 'screens/Checkout/Checkout'),
      props: {
        path: '/:planId',
        exact: true,
      },
    },
    {
      name: 'PostCheckout',
      factory: () =>
        import(
          /* webpackChunkName: "PostCheckout" */ 'screens/PostCheckout/PostCheckout'
        ),
      props: {
        path: '/:planId/thank-you',
        exact: true,
      },
    },
  ];

  let _screenComponents;
  let _routeComponents;

  function getRouteComponents() {
    const screenComponents = getScreenComponents();

    if (!_routeComponents) {
      _routeComponents = _screens.map(screen => ({
        ...screen.props,
        component: screenComponents[screen.name],
      }));
    }

    return _routeComponents;
  }

  function getScreenComponents() {
    if (!_screenComponents) {
      _screenComponents = _screens.reduce(
        (acc, screen) => ({
          ...acc,
          [screen.name]: toLazyComponent(screen.factory),
        }),
        {},
      );
    }

    return _screenComponents;
  }

  function toLazyComponent(factory) {
    const factoryWithRetry = () => _retry(factory);

    const Component = React.lazy(factoryWithRetry);

    // Add preload to have a chance to preload
    // content when you need
    Component.preload = factoryWithRetry;

    return Component;
  }

  function _retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch(error => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              reject(error);
            } else {
              _retry(fn, retriesLeft - 1, interval).then(resolve, reject);
            }
          }, interval);
        });
    });
  }

  return {
    getRouteComponents,
    getScreenComponents,
    toLazyComponent,
  };
}

Routes.displayName = 'Routes';

export default createService(Routes);
