import toArray from './toArray';

function toUniqueArray(...args) {
  const valids = args
    .reduce((acc, arg) => (!arg ? acc : [...acc, ...toArray(arg)]), [])
    .filter(v => v);

  const unique = [...new Set(valids)];

  return unique;
}

export default toUniqueArray;
