import createService from 'utils/createService';
import DeviceInfo from 'utils/DeviceInfo';
import { fallbackPlanId } from 'utils/env';

function Location() {
  let _planId;

  function getPlanId() {
    if (!_planId) {
      // Use slice(1) to ignore first character '/'
      _planId = window.location.pathname.slice(1);
    }

    return _planId;
  }

  function getOrigin() {
    const { qp_origin } = getQueryParams();

    const name = qp_origin || DeviceInfo.getDevice();

    return `qp-${name}`;
  }

  function forceRedirect(href) {
    window.location.href = href;
  }

  function forcePathname(pathname = fallbackPlanId) {
    window.location.pathname = `/${pathname}`.replace(/\/\//g, '/');
  }

  function getQueryParams() {
    return toQueryParams(window.location.search);
  }

  function toQueryParams(queryString = '') {
    if (!queryString) {
      return {};
    }

    const splits = queryString.replace('?', '').split('&');

    const result = {};
    for (let i = 0; i < splits.length; i += 1) {
      const queryPartial = splits[i];
      const [key, value] = queryPartial.split('=');

      if (value) {
        result[key] = decodeURIComponent(value);
      }
    }

    return result;
  }

  function toQueryString(queryParams = {}) {
    const keys = Object.keys(queryParams);

    if (!keys.length) {
      return '';
    }

    let result = '';
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const value = queryParams[key];

      // Only add non undefined values
      if (value !== undefined) {
        result += `&${key}=${value}`;
      }
    }

    return '?' + result.slice(1);
  }

  function mergeQueryString(queryString) {
    return window.location.search
      ? `${window.location.search}&${queryString.slice(1)}`
      : queryString;
  }

  function mergeQueryParams(queryParams) {
    return mergeQueryString(toQueryString(queryParams));
  }

  function withQueryParams(to, extraParams) {
    const extraParamsString = _handleExtraParams(extraParams);

    return `${to}${window.location.search}${extraParamsString}`;
  }

  function _handleExtraParams(extraParams) {
    if (!extraParams) {
      return '';
    }

    const extraParamsString = toQueryString(extraParams);

    return window.location.search
      ? `&${extraParamsString.slice(1)}`
      : extraParamsString;
  }

  return {
    forceRedirect,
    forcePathname,
    getPlanId,
    getQueryParams,
    getOrigin,
    toQueryString,
    mergeQueryString,
    toQueryParams,
    withQueryParams,
    mergeQueryParams,
  };
}

Location.displayName = 'Location';

export default createService(Location);
