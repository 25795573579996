const colors = {
  lightBlack: '#555555',
  mediumBlack: '#2a2a2a',
  statusGrey: '#dddddd',
  statusGreen: '#88CC88',
  statusRed: '#D46A6A',
  transparent: 'transparent',
  default: '#555',
  orange: '#ff4a00',
  blue: '#2686ff',
  darkBlue: '#1a62bc',
  lightGrey: '#e7e7e7',
  black: '#000',
  white: '#fff',
  lightWhite: '#f4f4f4',
  lightgreen: '#65cf72',
  superlightgrey: '#f1f1f1',
  footerBlack: '#313131',
  footerTextColor: '#e8e8e8',
  whatsapp_green: '#65cf72',
  facebook_purple: '#4267b2',
  messenger_blue: '#0084ff',
  copiar_link: '#32CD32',
  darkPurple: '#7100b8',
};

const convertLuminosity = luminosity => {
  // convert percent string number or number -100 to 100
  // into float number between -1.00 and +1.00
  const lum =
    (typeof luminosity === 'string'
      ? parseFloat(luminosity.replace(/%/i, ''), 10)
      : luminosity) / 100;

  // when overflows truncate number between -1 and 1
  return Math.max(Math.min(lum, 1), -1);
};

export const withBrightness = (color, luminosity = 0) => {
  // omit # from hexColor
  const hex = color.replace(/^\s*#|\s*$/g, '');

  const lum = convertLuminosity(luminosity);

  // increse/decrease luminosity
  let rgb = '#';

  for (let c, i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export function getContrastColor(hex) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // http://stackoverflow.com/a/3943023/112731
  return r * 0.299 + g * 0.587 + b * 0.114 > 186
    ? colors.mediumBlack
    : colors.white;
}

export default colors;
