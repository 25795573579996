// TODO: mergeRight should behave like mergeDeepRight
// because when deep objects are trying to be merged is unstable
function mergeRight(...objects) {
  return objects.reduce(
    (acc, curr) => ({
      ...acc,
      ...curr,
    }),
    {},
  );
}

export default mergeRight;
