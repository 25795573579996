import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Routes from 'services/Routes';
import Location from 'services/Location';
import AppState from 'services/AppState';
import createLogError from 'utils/createLogError';
import { isProductionEnv, fallbackPlanId } from 'utils/env';
import RouteLoader from 'components/RouteLoader/RouteLoader';
import HeaderTags from 'components/HeaderTags/HeaderTags';

import GlobalStyles from './GlobalStyles';

const routes = Routes.getRouteComponents();
class App extends Component {
  state = {
    metadata: {},
  };

  componentDidCatch(error, info) {
    if (isProductionEnv()) {
      const logError = createLogError();

      logError(error, info.componentStack);
    }
  }

  async componentDidMount() {
    try {
      const metadata = await AppState.getMetadata();

      // Always redirect to fallbackPlanId when metadata cannot be found
      // or to redirectTo when plan is deprecated
      if (metadata.isDeprecated && metadata.redirectTo) {
        Location.forcePathname(metadata.redirectTo);
      }

      this.setState({ metadata: metadata });
    } catch (err) {
      Location.forcePathname(fallbackPlanId);
    }
  }

  render() {
    const { metadata } = this.state;

    return (
      <>
        <GlobalStyles />
        <HeaderTags metadata={metadata} />
        <BrowserRouter>
          <Suspense fallback={<RouteLoader />}>
            <Switch>
              {routes.map(routeProps => {
                const Component = routeProps.component;
                const newRouteProps = { ...routeProps };

                delete newRouteProps.component;

                return (
                  <Route
                    key={routeProps.path}
                    render={props => (
                      <Component metadata={metadata} {...props} />
                    )}
                    {...newRouteProps}
                  />
                );
              })}
            </Switch>
          </Suspense>
        </BrowserRouter>
      </>
    );
  }
}

App.displayName = 'App';

export default App;
