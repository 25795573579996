export const {
  REACT_APP_HOSTNAME_URL: hostnameUrl,
  REACT_APP_ASSETS_URL: baseAssetsUrl,
  REACT_APP_API_MICROSERVICE_URL: baseUrl,
  REACT_APP_ENCRYPTION_KEY: pagarmePublicKey,
  REACT_APP_NODE_ENV_SHORT_NAME: envShortName,
  REACT_APP_VERSION: appVersion,
  REACT_APP_ZENDESK_ID: zendeskID,
  REACT_APP_GOOGLE_TAG_MANAGER_ID: googleTagManagerID,
  REACT_APP_HOTJAR_ID: hotjarID,
  REACT_APP_FALLBACK_FACEBOOK_PIXEL_ID: fallbackFacebookPixelID,
  REACT_APP_FALLBACK_GOOGLE_ANALYTICS_ID: fallbackGoogleAnalyticsID,
  REACT_APP_FALLBACK_PLAN_ID: fallbackPlanId,
} = process.env;

function isProductionEnv() {
  // create-react-app will always resolve build to production env
  // so to create a controlled **prod** environment even if production
  // build always resolve NODE_ENV to "prod" envShortName variable was created.
  return envShortName === 'prod';
}

function isDevelopmentEnv() {
  /// create-react-app will always resolve build to production env
  // so to create a controlled **dev** environment even if production
  // build always resolve NODE_ENV to "prod" envShortName variable was created.
  return envShortName === 'dev';
}

function isTestEnv() {
  return process.env.NODE_ENV === 'test';
}

function getPostMessageTargetOrigin() {
  return isDevelopmentEnv() ? '*' : `https://${hostnameUrl}`;
}

export {
  getPostMessageTargetOrigin,
  isProductionEnv,
  isDevelopmentEnv,
  isTestEnv,
};
