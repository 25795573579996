import axios from 'axios';
import { baseUrl } from 'utils/env';

import createService from 'utils/createService';
import mergeRight from 'utils/mergeRight';
import toArray from 'utils/toArray';
import toUniqueArray from 'utils/toUniqueArray';
import {
  isDevelopmentEnv,
  fallbackGoogleAnalyticsID,
  fallbackFacebookPixelID,
  baseAssetsUrl,
  envShortName,
} from 'utils/env';

function AppState() {
  let _planId;
  let _metadata;
  let _images;

  const _fallbackMetadata = {
    analytics: toArray(fallbackGoogleAnalyticsID),
    pixels: toArray(fallbackFacebookPixelID),
  };

  function getPlanId() {
    if (!_planId) {
      // Use slice(1) to ignore first character '/'
      _planId = window.location.pathname.slice(1);
    }

    return _planId;
  }

  async function getMetadata() {
    if (!_metadata) {
      const planId = getPlanId();

      _metadata = await _getMetadataByPlanId(planId);
    }

    return _metadata;
  }

  function getImages(metadata) {
    if (!metadata) {
      return;
    }

    if (!_images) {
      const origin = `${baseAssetsUrl}`;
      const baseName = `${origin}/checkout/${envShortName}`;

      _images = {
        logo: `${origin}/${metadata.logo}`,
        banner: `${origin}/${metadata.banner}`,
        bannerMobile: `${origin}/${metadata.bannerMobile}`,
        sideBanner: `${origin}/${metadata.sideBanner}`,
        footerBanner: `${origin}/${metadata.footerBanner}`,
        footerBadges: `${origin}/${metadata.footerBadges}`,
      };

      const planId = getPlanId();

      if (planId === '274') {
        const aditionalBanners = {
          desktop: ['banner2'],
          mobile: ['banner2-mobile', 'banner3-mobile', 'banner4-mobile'],
        };
        const aditionalBannersMap = { desktop: {}, mobile: {} };
        Object.keys(aditionalBanners).forEach(
          device =>
            Array.isArray(aditionalBanners[device]) &&
            aditionalBanners[device].forEach(
              banner =>
                (aditionalBannersMap[device][
                  banner
                ] = `${baseName}/${metadata.alias}-${banner}.png`),
            ),
        );
        _images['aditionalBanners'] = aditionalBannersMap;
      }
    }

    return _images;
  }

  async function _getMetadataByPlanId(planId) {
    try {
      const response = await axios.get(`${baseUrl}/checkout/plan/${planId}`);
      const completeMetadata = response.data;
      const { checkout } = completeMetadata.metadata;

      const { activePlans } = checkout;
      const data = { ...completeMetadata, ...checkout };

      // check is planId is an activePlan
      if (activePlans.indexOf(planId) !== -1) {
        const { analytics = [], pixel = {}, ...rest } = data;

        // Overrides metadata analytics and pixel in development mode
        if (isDevelopmentEnv()) {
          return mergeRight(rest, _fallbackMetadata);
        }

        // This way we can keep tracking data with Engenharia Analytics
        const _analytics = toUniqueArray(
          analytics,
          _fallbackMetadata.analytics,
        );

        // Only add pixel tracking whether equals to correct production plan
        if (planId === pixel.plan) {
          return mergeRight(rest, {
            pixels: pixel.ids,
            analytics: _analytics,
          });
        }

        // Otherwise only keep tracking analytics in production mode
        return mergeRight(rest, { analytics: _analytics });
      }

      return;
    } catch (err) {
      return;
    }
  }

  return {
    getMetadata,
    getImages,
    getPlanId,
  };
}

AppState.displayName = 'AppState';

export default createService(AppState);
