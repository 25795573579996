const createService = (function() {
  const instances = {};

  return function(service) {
    const name = service.displayName || service.name;

    if (name && !instances[name]) {
      instances[name] = service();
    }

    return instances[name];
  };
})();

export default createService;
