import axios from 'axios';
import getStackTrace from './getStackTrace';
import DeviceInfo from './DeviceInfo';
import { baseUrl } from './env';

function createLogError(user) {
  return function logError(error, stackTrace) {
    const stackTraceError = getStackTrace(error);

    const errorData = {
      attachments: [
        {
          author_name: 'Queima Pay',
          author_icon:
            'https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png',
          fallback: error.message,
          pretext: error.message,
          color: '#61DAFB',
          fields: [
            {
              title: 'Error',
              value: error.message,
              short: false,
            },
            {
              title: 'Device',
              value: DeviceInfo.getDevice(),
              short: true,
            },
            {
              title: 'Version',
              value: DeviceInfo.getVersion(),
              short: true,
            },
            {
              title: 'User Agent',
              value: DeviceInfo.getUserAgent(),
              short: false,
            },
            ...(!user
              ? [
                  {
                    title: 'Location',
                    value: DeviceInfo.getLocation(),
                    short: false,
                  },
                ]
              : [
                  {
                    title: 'Location',
                    value: DeviceInfo.getLocation(),
                    short: false,
                  },
                  {
                    title: 'User id',
                    value: user.id,
                    short: true,
                  },
                  {
                    title: 'User e-mail',
                    value: user.email,
                    short: true,
                  },
                  {
                    title: 'Token',
                    value: localStorage.getItem('token'),
                    short: false,
                  },
                ]),
            {
              title: 'Stack',
              value: stackTrace,
              short: false,
            },
            {
              title: 'StackError',
              value: stackTraceError,
              short: false,
            },
          ],
          footer: 'Slack API',
          footer_icon:
            'https://platform.slack-edge.com/img/default_application_icon.png',
        },
      ],
    };

    // Dispatch to error logger
    axios.post(`${baseUrl}/slack`, errorData);
  };
}

export default createLogError;
