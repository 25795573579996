import styled, { keyframes } from 'styled-components';
import colors from 'utils/colors';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader = styled.div`
  width: 60px;
  height: 60px;
  border: 5px solid ${colors.statusGrey};
  border-top: 5px solid ${colors.white};
  border-radius: 50%;

  animation: 1s linear ${spin} infinite;
`;
